import * as api from '../api';
import { PURGE } from 'redux-persist';


const GET_MESSAGES = 'messages/GET_MESSAGES';
const POST_PUSH_MESSAGE = 'admins/POST_PUSH_MESSAGE';
const POST_APP_MESSAGE = 'admins/POST_APP_MESSAGE';
const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGES:
      return action.data;
    case POST_PUSH_MESSAGE:
      return state;
    case POST_APP_MESSAGE:
      return state;
    case PURGE:
    default:
      return state;
  }
}

export const getMessages = (jwt, fleetId) => {
  return (dispatch) => {
    return api
    .getMessages(jwt, fleetId)
    .then((messages ) => { 
      console.log("messages:", messages)
      if (messages) {
        dispatch({ type: GET_MESSAGES, data: messages });
      } else {
        throw new Error('There was a problem getting umessages');
      }
    });
  }
};

export const postPushMessage = (jwt, fleetId, message) => {
  return (dispatch) => {
    return api
      .postPushMessage(jwt, fleetId, message)
      .then(({ message }) => dispatch({ type: POST_PUSH_MESSAGE, data: message }));
  };
};

export const postAppMessage = (jwt, fleetId, message) => {
  return (dispatch) => {
    console.log("dispatch app")
    return api
      .postAppMessage(jwt, fleetId, message)
      .then(({ message }) => dispatch({ type: POST_APP_MESSAGE, data: message }));
  };
};

export const clearUser = () => {
  return (dispatch) => dispatch({ type: PURGE, result: () => null });
};
